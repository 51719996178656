const routes = [
  {
    path: ["/support"],
    exact: true,
    component: "Support"
  },
  {
    path: ["/", "/home"],
    exact: false,
    component: "Home",
  }
];

export default routes;
